import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Affix, Button, Popconfirm, Space, Tag, Tooltip } from "antd"
import PageRoot from "../../../components/PageRoot"
import { InfoOutlined, PlusOutlined } from "@ant-design/icons"
import LoadingScreen from "../../../components/LoadingScreen"
import useColumns from "../../../hooks/useColumns"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import { COURSE, MASTER, PATH } from "../../../enums/CourseTypes"

const ALL_BUNDLE_LIST_QUERY = gql`
  {
    bundles(order_by: { title: asc }) {
      id
      created_at
      state
      title
      subtitle
      short_description
      course_type
      purchases(limit: 1) {
        id
      }
    }
  }
`

const DELETE_BUNDLE_MUATION = gql`
  mutation deleteBundle($id: uuid!) {
    delete_bundles_by_pk(id: $id) {
      id
    }
    delete_bundle_videos(where: { bundle_id: { _eq: $id } }) {
      affected_rows
    }
    delete_seo(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const ARCHIVE_BUNDLE_MUATION = gql`
  mutation archiveBundle($id: uuid!) {
    update_bundles_by_pk(pk_columns: { id: $id }, _set: { state: "ARCHIVED" }) {
      id
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_WAREHOUSE,
  ROLE_PRODUCT_MANAGER,
]

const courseTypesTranslation = {
  [COURSE]: "Corso",
  [PATH]: "Percorso",
  [MASTER]: "Master",
}

const courseTypeColors = {
  [COURSE]: "green",
  [PATH]: "blue",
  [MASTER]: "purple",
}

const BundleListPage = () => {
  const columnOptions = useColumns()

  const [refreshing, setRefreshing] = useState(false)
  const [deleteBundle] = useMutation(DELETE_BUNDLE_MUATION)
  const [archiveBundle] = useMutation(ARCHIVE_BUNDLE_MUATION)
  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const deleteRecord = async id => {
    try {
      await deleteBundle({ variables: { id } })
      setRefreshing(true)
    } catch (error) {}
  }

  const columns = [
    {
      ...columnOptions.state,
    },
    {
      ...columnOptions.createdAt,
    },
    {
      title: "Tipologia",
      filters: [
        {
          text: "Percorso",
          value: PATH,
        },
        {
          text: "Master",
          value: MASTER,
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.course_type === value,
      render: record => {
        return (
          <Tag color={courseTypeColors[record.course_type]}>
            {courseTypesTranslation[record.course_type]}
          </Tag>
        )
      },
    },
    {
      ...columnOptions.title,
    },
    {
      ...columnOptions.subtitle,
    },
    {
      title: "Azioni",
      render: record => {
        const hasPurchase = record.purchases.length > 0

        // if (hasPurchase) {
        //   return (
        //     <Space>
        //       <Tooltip
        //         placement="topLeft"
        //         title="Non puoi modificare un bundle venduto!"
        //       >
        //         <Button type="primary" shape="circle" icon={<InfoOutlined />} />
        //       </Tooltip>
        //       <Popconfirm
        //         placement="topRight"
        //         title={"Sei sicuro di voler archiviare questo bundle?"}
        //         onConfirm={async () => {
        //           await archiveBundle(record.id)
        //           window.location.refresh()
        //         }}
        //         okText="elimina"
        //         cancelText="annulla"
        //       >
        //         <Button type="danger">Archivia</Button>
        //       </Popconfirm>
        //     </Space>
        //   )
        // }

        return (
          <Space>
            {/*<Button><Link to={`/bundles/view?id=${record.id}`}>mostra</Link></Button>*/}
            <Button type="primary">
              <Link to={`/promo/bundles/edit?id=${record.id}`}>modifica</Link>
            </Button>
            {/*<Popconfirm*/}
            {/*  placement="topRight"*/}
            {/*  title={"Sei sicuro di voler eliminare questo record?"}*/}
            {/*  onConfirm={() => {*/}
            {/*    deleteRecord(record.id)*/}
            {/*  }}*/}
            {/*  okText="elimina"*/}
            {/*  cancelText="annulla"*/}
            {/*>*/}
            {/*  <Button type="danger">elimina</Button>*/}
            {/*</Popconfirm>*/}
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/promo/bundles/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>
      <DataTable
        dataKey={"bundles"}
        query={ALL_BUNDLE_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default BundleListPage
