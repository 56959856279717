import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Button, Space, Tag } from "antd"
import PageRoot from "../../../components/PageRoot"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import { formatDate, uiHandleError } from "../../../utils"
import moment from "moment"
import { useAuth0 } from "@auth0/auth0-react"
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import { UPDATE_CERTIFICATION_SHIPPED_MUTATION } from "../../../components/CertificationShippedSetter"

const ALL_USER_CERTIFICATION_LIST_QUERY = gql`
  {
    user_inventory_items(
      where: { item_type: { _eq: "CERTIFICATION" } }
      distinct_on: [item_id, user_id]
    ) {
      user_id
      certification_state {
        exam_submit_at
        last_exam_at
        passed
        shipped
        shipping_required
      }
      certification {
        title
        id
      }
      user {
        basic_profile {
          firstname
          lastname
          phone_number
          email
          user_id
        }
        billing_profile {
          firstname
          lastname
          phone_number
          email
        }
      }
    }
  }
`

const SET_CERTIFICATION_SHIPPED_MUTATION = gql`
  mutation setCertificationsShipped($certificationIds: [uuid!]!) {
    update_user_certification_states(
      _set: { shipped: true }
      where: { certification_id: { _in: $certificationIds } }
    ) {
      affected_rows
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_SALE,
  ROLE_WAREHOUSE,
  ROLE_PRODUCT_MANAGER,
]

const UserCertificationListPage = () => {
  const titleSearchColumnOptions = useColumnSearch({ dataIndex: "title" })
  const firstnameSearchColumnOptions = useColumnSearch({
    dataIndex: "user.firstname",
  })
  const lastnameSearchColumnOptions = useColumnSearch({
    dataIndex: "user.lastname",
  })
  const [refreshing, setRefreshing] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [selectedCertifications, setSelectedCertifications] = useState(null)
  const [selectedCertificationState, setSelectedCertificationState] = useState(
    []
  )
  const { getAccessTokenSilently } = useAuth0()

  const [setCertificationShipped] = useMutation(
    SET_CERTIFICATION_SHIPPED_MUTATION
  )

  const [updateShipped] = useMutation(UPDATE_CERTIFICATION_SHIPPED_MUTATION)

  const handleShippedClick = async data => {
    if (!selectedCertifications) {
      return
    }

    setDownloading(true)

    try {
      // await setCertificationShipped({
      //   variables: {
      //     certificationIds: selectedCertifications,
      //   },
      // })

      await Promise.all(
        selectedCertificationState.map(item =>
          updateShipped({
            variables: {
              userId: item.user_id,
              certificationId: item.id,
              shipped: true,
            },
          })
        )
      )

      window.location.reload()
    } catch (error) {
      uiHandleError({ error })
    }

    setDownloading(false)
  }

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const columns = [
    {
      title: "Titolo",
      dataIndex: "title",
      ...titleSearchColumnOptions,
    },
    {
      title: "Stato",
      filters: [
        {
          text: "Passato",
          value: true,
        },
        {
          text: "Non passato",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.passed === value,
      render: record => {
        const temp = record.passed
          ? { color: "blue", text: "Passato" }
          : { color: "orange", text: "Non passata" }
        return <Tag color={temp.color}>{temp.text}</Tag>
      },
    },
    {
      title: "Esame sostenuto il",
      filters: [
        {
          text: "Non ancora sostenuto",
          value: [null, undefined],
        },
      ],
      filterMultiple: false,
      sorter: (a, b) => {
        return moment(a.last_exam_at) - moment(b.last_exam_at)
      },
      onFilter: (value, record) => value.includes(record?.last_exam_at),
      render: record => {
        return record?.last_exam_at
          ? formatDate(record.last_exam_at)
          : "Non ancora sostenuto"
      },
    },
    {
      title: "Spedizione richiesta",
      filters: [
        {
          text: "Richiede spedizione",
          value: true,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.passed === value,
      render: record => {
        return record.shipping_required ? (
          <Tag color="blue">Richiede spedizione</Tag>
        ) : null
      },
    },
    {
      title: "Spedito",
      filters: [
        {
          text: "Spedito",
          value: true,
        },
        {
          text: "Non spedito",
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.shipped === value,
      render: record => {
        return record.shipped ? <Tag color="blue">Spedito</Tag> : null
      },
    },
    {
      title: "Utente",
      children: [
        {
          title: "Nome",
          ...firstnameSearchColumnOptions,
        },
        {
          title: "Cognome",
          ...lastnameSearchColumnOptions,
        },
      ],
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            {/*<Button><Link to={`/certifications/view?id=${record.id}`}>mostra</Link></Button>*/}
            {/*{!record.shipped && (*/}
            {/*  <Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      handleShippedClick(record)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    imposta come spedito*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Button type="primary" disabled={!record.id || !record.user_id}>
              <Link
                to={`/users/certifications/view?itemId=${
                  record.id
                }&userId=${btoa(record.user_id)}`}
              >
                visualizza
              </Link>
            </Button>
          </Space>
        )
      },
    },
  ]

  const handleDownloadShippingData = async () => {
    setDownloading(true)
    try {
      const token = await getAccessTokenSilently()
      const config = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      const response = await fetch(
        "/.netlify/functions/download-certification-shipping-data",
        config
      )
      response.blob().then(b => {
        const a = document.createElement("a")
        a.href = URL.createObjectURL(b)
        a.setAttribute(
          "download",
          `certificazioni-da-spedire-${moment().format("L")}`
        )
        a.click()
        setDownloading(false)
      })
    } catch (err) {
      setDownloading(false)
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCertificationState(selectedRows)
      setSelectedCertifications(selectedRows.map(item => item.id))
    },
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Space>
        <Button
          type="primary"
          size="large"
          onClick={handleDownloadShippingData}
          loading={downloading}
        >
          Scarica dati certificazioni da spedire
        </Button>
        <Button
          disabled={!(selectedCertifications?.length > 0)}
          type="primary"
          size="large"
          onClick={handleShippedClick}
          loading={downloading}
        >
          imposta come "Spedito"
        </Button>
      </Space>
      <br />
      <br />
      <DataTable
        rowKey={record => record.user_id + record.id}
        rowSelection={{
          ...rowSelection,
        }}
        dataTransformer={data => {
          return data.user_inventory_items.map(
            ({ certification_state, certification, user, user_id }) => {
              const userData = {
                ...user.basic_profile,
                ...user.billing_profile,
              }

              return {
                user_id,
                ...certification,
                ...certification_state,
                user: userData,
              }
            }
          )
        }}
        query={ALL_USER_CERTIFICATION_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default UserCertificationListPage
