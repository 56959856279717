import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import BundleForm from "../../../components/forms/BundleForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"

const GET_BUNDLE_QUERY = gql`
  query getBundle($id: uuid!) {
    bundles_by_pk(id: $id) {
      description
      landscape_id
      id
      course_type
      short_description
      state
      subtitle
      title
      wallpaper_id
      header_image_id
      price
      seo {
        title
        description
        slug
        item_id
        item_type
      }
      item_rels {
        bundle_id
        item_id
      }
    }
  }
`

const UPDATE_BUNDLE_MUTATION = gql`
  mutation updateBundle(
    $id: uuid!
    $data: bundles_set_input!
    $seo: seo_insert_input!
  ) {
    update_bundles_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
  }
`

const DELETE_ALL_BUNDLE_RELS_MUTATIONS = gql`
  mutation deleteBundleRels($id: uuid!) {
    delete_item_bundle_rels(where: { bundle_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const INSERT_ALL_BUNDLE_RELS_MUTATIONS = gql`
  mutation insertBundleRels($items: [item_bundle_rels_insert_input!]!) {
    insert_item_bundle_rels(objects: $items) {
      affected_rows
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const EditBundlePage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_BUNDLE_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateBundle] = useMutation(UPDATE_BUNDLE_MUTATION)
  const [deleteBundleRels] = useMutation(DELETE_ALL_BUNDLE_RELS_MUTATIONS)
  const [insertBundleRels] = useMutation(INSERT_ALL_BUNDLE_RELS_MUTATIONS)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    delete newValues.items
    delete newValues.seo
    const itemData = values.items
      ? values.items.map((itemId, index) => ({
          item_id: itemId,
          bundle_id: params.id,
        }))
      : []

    try {
      const seo = values.seo
      seo.item_id = params.id

      await updateBundle({ variables: { data: newValues, id: params.id, seo } })
      await deleteBundleRels({ variables: { id: params.id } })
      await insertBundleRels({
        variables: {
          items: itemData,
        },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/bundles")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const bundle = data.bundles_by_pk

  if (!bundle) {
    return (
      <PageRoot>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/promo/bundles"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...bundle,
    items: bundle.item_rels.map(item => item.item_id),
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <BundleForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditBundlePage
