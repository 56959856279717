import React, { useState } from "react"
import { Modal, Select } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { gql, useMutation } from "@apollo/client"

export const UPDATE_CERTIFICATION_SHIPPED_MUTATION = gql`
  mutation updateCertificationShippedState(
    $userId: String!
    $certificationId: uuid!
    $shipped: Boolean
  ) {
    update_user_certification_states_by_pk(
      pk_columns: { user_id: $userId, certification_id: $certificationId }
      _set: { shipped: $shipped }
    ) {
      shipped
    }
  }
`

const CertificationShippedSetter = ({
  certificationId,
  userId,
  shipped = false,
}) => {
  const [updateShipped] = useMutation(UPDATE_CERTIFICATION_SHIPPED_MUTATION)
  const [internalShipped, setInternalShipped] = useState(shipped)

  const handleSelect = async value => {
    Modal.confirm({
      title: "Impostazione ruolo",
      icon: <ExclamationCircleOutlined />,
      content: `Stai cambiando lo stato di spedizione a "${
        value ? "Spedito" : "Non spedito"
      }".`,
      onOk: async () => {
        try {
          await updateShipped({
            variables: {
              userId,
              certificationId,
              shipped: value,
            },
          })
          setInternalShipped(value)
        } catch (err) {
          console.log({ updateUserRoleError: err })
        }
      },
      onCancel() {
        setInternalShipped(internalShipped)
      },
    })
  }

  return (
    <Select
      onChange={handleSelect}
      value={internalShipped}
      style={{ width: 160 }}
    >
      <Select.Option value={true}>Spedito</Select.Option>
      <Select.Option value={false}>Non spedito</Select.Option>
    </Select>
  )
}

export default CertificationShippedSetter
