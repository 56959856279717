import React from "react"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd"
import FormFileUploader from "../customFormItems/FormFileUploader"
import FormQuill from "../customFormItems/FormQuill"
import {
  BUNDLE,
  CERTIFICATION,
  COURSE,
  SUBSCRIPTION,
} from "../../enums/ItemTypes"
import CertificationSelector from "../customFormItems/CertificationSelector"
import CourseSelector from "../customFormItems/CourseSelector"
import { PERCENTAGE, VALUE } from "../../enums/ValueTypes"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"

const CouponCriterionForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()

  return (
    <Form
      scrollToFirstError
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Bonus">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Titolo"
                      name="title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Sottotitolo" name="subtitle">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item label="Descrizione breve" name="short_description">
                  <Input.TextArea rows={6} maxLength={230} showCount />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Descrizione" name="description">
              <FormQuill />
            </Form.Item>

            <Form.Item label="immagine bonus" name="picture_id">
              <FormFileUploader accept={"image/*"} />
            </Form.Item>
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  label="Applicabile per"
                  name="exclusive_for_content_type"
                >
                  <Select>
                    <Select.Option value={null}>Tutti i tipi</Select.Option>
                    <Select.Option value={COURSE}>Corso</Select.Option>
                    <Select.Option value={CERTIFICATION}>
                      Certificazione
                    </Select.Option>
                    <Select.Option value={SUBSCRIPTION}>
                      Abbonamento
                    </Select.Option>
                    <Select.Option value={BUNDLE}>Pacchetto</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const contentType = getFieldValue(
                      "exclusive_for_content_type"
                    )

                    if (contentType === COURSE) {
                      return (
                        <Form.Item
                          name="exclusive_for_content_id"
                          label={`Seleziona corso. Se questo bonus è applicabile per tutti i corsi, selezionare il valore "nessuno"`}
                        >
                          <CourseSelector onDemandOnly />
                        </Form.Item>
                      )
                    }

                    if (contentType === CERTIFICATION) {
                      return (
                        <Form.Item
                          name="exclusive_for_content_id"
                          label={`Seleziona certificato. Se questo bonus è applicabile per tutti i certificati, selezionare il valore "nessuno"`}
                        >
                          <CertificationSelector />
                        </Form.Item>
                      )
                    }

                    return null
                  }}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Tipo di sconto" name="discount_type">
                  <Select>
                    <Select.Option value={VALUE}>valore</Select.Option>
                    <Select.Option value={PERCENTAGE}>
                      percentuale
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const discountType = getFieldValue("discount_type")

                    if (discountType === VALUE) {
                      return (
                        <Form.Item name="value" label={`Valore da scontare`}>
                          <InputNumber style={{ width: "100%" }} min={0} />
                        </Form.Item>
                      )
                    }

                    if (discountType === PERCENTAGE) {
                      return (
                        <Form.Item
                          name="percentage"
                          label={`Percentuale da scontare`}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={1}
                            step={0.1}
                            formatter={value =>
                              `${((value || 0) * 100).toFixed(0)}%`
                            }
                            parser={value =>
                              Number(value.replace("%", "")) / 100.0
                            }
                          />
                        </Form.Item>
                      )
                    }

                    return null
                  }}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="start_at" label="Data inizio">
                  <DatePicker showTime style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="end_at" label="Data fine">
                  <DatePicker showTime style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          {/*<Affix offsetTop={16}>*/}
          {/*  <Card title="SEO">{seoItems}</Card>*/}
          {/*</Affix>*/}
        </Col>
      </Row>

      <br />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CouponCriterionForm
